import Grid from "@mui/material/Grid";
import Icon from "components/dist/atoms/Icon";
import Input from "components/dist/atoms/Input";
import Select from "components/dist/atoms/Select";
import Text from "components/dist/atoms/Text";
import React from "react";
import { Address } from "src/backend";
import { useGetConstantsQuery } from "src/services/appApi";
import { useIsMounted } from "usehooks-ts";

import { GooglePlacesAutocomplete } from "../../google-places-textfield";
import { MaskZipCodeInput } from "../../mask-zip-code-input";
import { FormikedInputProps } from "../formik-props";

const AddressFields = [
    "streetAddressLine1",
    "streetAddressLine2",
    "citySuburb",
    "stateProvinceRegion",
    "postalCode",
]

export const FormikedAddressField = <Values,>(props: FormikedInputProps<Values>) => {
    const hasAnyValue = AddressFields.some((field) => Boolean(props.formik.values[props.name]?.[field]));

    const [isManual, setIsManual] = React.useState(hasAnyValue);
    const { data } = useGetConstantsQuery();
    const isMounted = useIsMounted();

    const onSelectDetails = async (value: Address) => {
        AddressFields.forEach((field) => {
            props.formik.setFieldValue(`${props.name}.${field}`, value[field]);
        });
        if (!props.formik.values['name'] && !props.disableAddressAutoName) {
            await props.formik.setFieldValue('name', value?.streetAddressLine1 ?? "");
            props.formik.setFieldTouched('name', true);
        }
        if (isMounted()) {
            setIsManual(true);
        }
    }

    return <>
        <Grid item xs={12}>
            <GooglePlacesAutocomplete
                freeSolo
                disableClearable
                currentAddress={props.formik.values[props.name]?.currentAddress}
                disabled={props.disabled}
                onEnterAddressManuallyClick={() => setIsManual(true)}
                onSelectDetails={onSelectDetails}
                onSelectStreet={async (value) => {
                    props.formik.setFieldValue(`${props.name}.streetAddressLine1`, value);
                }}
                // placeholder={props.placeholder}
                value={props.formik.values[props.name]?.streetAddressLine1 ?? ""}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <Input
                            {...params.inputProps}
                            className=""
                            error={props.error}
                            autoComplete='new-password'
                            placeholder={props.placeholder}
                            disabled={props.disabled}
                            aria-label={props.placeholder} >
                            <Input.Suffix>
                                <Icon
                                    name="MapPin"
                                    width={20}
                                    height={20}
                                    strokeWidth={1.5}
                                    className="text-blue-100" />
                            </Input.Suffix>
                        </Input>
                    </div>

                )}
                options={[]} />
        </Grid>
        {isManual && <>
            <Grid item xs={12} md={12}>
                <Input
                    disabled={props.disabled}
                    placeholder='Suite, Apt, Unit'
                    error={Boolean(props.formik.touched[props.name]?.streetAddressLine2 && props.formik.errors[props.name]?.streetAddressLine2)}
                    name={`${props.name}.streetAddressLine2`}
                    value={props.formik.values[props.name]?.streetAddressLine2 ?? ""}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                />
                <Text size="xs" variant="destructive">
                    {props.formik.touched[props.name]?.streetAddressLine2 && props.formik.errors[props.name]?.streetAddressLine2}
                </Text>
            </Grid>
            <Grid item xs={12} md={4}>
                <Input
                    disabled={props.disabled}
                    placeholder='City'
                    error={Boolean(props.formik.touched[props.name]?.citySuburb && props.formik.errors[props.name]?.citySuburb)}
                    name={`${props.name}.citySuburb`}
                    value={props.formik.values[props.name]?.citySuburb ?? ""}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}

                />
                <Text size="xs" variant="destructive">
                    {props.formik.touched[props.name]?.citySuburb && props.formik.errors[props.name]?.citySuburb}
                </Text>
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    disabled={props.disabled}
                    name={`${props.name}.stateProvinceRegion`}
                    value={props.formik.values[props.name]?.stateProvinceRegion ?? ""}
                    onValueChange={(value) => {
                        props.formik.setFieldValue(`${props.name}.stateProvinceRegion`, value);
                    }}
                >
                    <Select.Trigger >
                        <Select.Value placeholder="State" />
                    </Select.Trigger>
                    <Select.Content>
                        {Object.keys(data?.statesMap || {}).map((stateAbbreviatedKey) => (<Select.Item key={stateAbbreviatedKey} value={stateAbbreviatedKey}>{data?.statesMap[stateAbbreviatedKey].unabbreviated}</Select.Item>))}
                    </Select.Content>
                </Select>
                <Text size="xs" variant="destructive">
                    {props.formik.touched[props.name]?.stateProvinceRegion && props.formik.errors[props.name]?.stateProvinceRegion}
                </Text>
            </Grid>
            <Grid item xs={12} md={4}>
                <Input
                    disabled={props.disabled}
                    placeholder='Zip Code'
                    error={Boolean(props.formik.touched[props.name]?.postalCode && props.formik.errors[props.name]?.postalCode)}
                    name={`${props.name}.postalCode`}
                    value={props.formik.values[props.name]?.postalCode ?? ""}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                    as={MaskZipCodeInput}
                />
                <Text size="xs" variant="destructive">
                    {props.formik.touched[props.name]?.postalCode && props.formik.errors[props.name]?.postalCode}
                </Text>
            </Grid>
        </>}
    </>
}